<template>
  <div>
    <div class="title">广告通知——通知公告</div>
    <div class="body">
      <div class="addBtn" @click="add">
        <a-button type="primary">新增</a-button>
      </div>
      <div class="item" v-for="item in advertise" :key="item.id">
        <!-- 标题 -->
        <div class="top">{{ item.title }}</div>
        <!-- 内容 -->
        <div class="content">
          {{ item.content }}
        </div>
        <!-- 发布时间 草稿 -->
        <div
          style="
            display: flex;
            justify-content: space-around;
            margin-bottom: 10px;
            margin-top: 3%;
          "
        >
          <div v-if="item.publishTime !== null">
            已发布于{{ item.publishTime }}
          </div>
          <div v-if="item.publishTime == null" style="color: orange">
            【草稿】
          </div>
          <a-switch
            :checked="item.isOpen === 1 ? true : false"
            checked-children="开"
            un-checked-children="关"
            @change="cardSwitch(item.isOpen === 0 ? 1 : 0, item)"
            default-checked
          />
        </div>
        <!-- 操作 -->
        <div class="bottom">
          <div
            v-if="item.publishTime !== null"
            @click="submitAgain(item)"
            style="width: 100%; text-align: center"
          >
            重新发布
          </div>
          <div
            v-if="item.publishTime == null"
            @click="edit(item)"
            style="width: 50%; text-align: center"
          >
            编辑
          </div>
          <div v-if="item.publishTime == null"><span>|</span></div>
          <!-- <div
            v-if="item.publishTime == null"
            style="width: 50%; text-align: center;"
          > -->
          <a-popconfirm
            v-if="item.publishTime == null"
            style="width: 50%; text-align: center"
            title="确定删除?"
            ok-text="是"
            cancel-text="否"
            @confirm="del(item)"
            @cancel="cancel"
          >
            <span style="color: rgba(0, 0, 0, 0.65)">删除</span>
          </a-popconfirm>
          <!-- </div> -->
        </div>
      </div>

      <!-- 新增弹窗 -->
      <a-drawer
        :title="title"
        placement="right"
        @close="close"
        :visible="visible"
        :get-container="false"
        width="500"
      >
        <a-form-model
          :model="entity"
          ref="ruleForm_add"
          :rules="addRules"
          style="position: relative; padding: 10px 40px"
        >
          <a-form-model-item prop="title" label="标题">
            <a-input placeholder="请输入" v-model="entity.title"> </a-input>
          </a-form-model-item>
          <a-form-model-item prop="content" label="发布内容">
            <a-textarea
              placeholder="请输入"
              v-model.trim="entity.content"
              style="height: 400px"
            ></a-textarea>
          </a-form-model-item>
        </a-form-model>

        <div
          :style="{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e8e8e8',
            padding: '10px 16px',
            textAlign: 'right',
            left: 0,
            background: '#fff',
            borderRadius: '0 0 4px 4px',
          }"
        >
          <a-button @click="close"> 取消 </a-button> &ensp;
          <a-button
            @click="save"
            style="background-color: orange; color: white"
          >
            保存为草稿
          </a-button>
          &ensp;
          <a-button type="primary" @click="submit"> 发布 </a-button>
        </div>
      </a-drawer>

      <!-- 编辑弹窗 -->
      <a-drawer
        :title="title"
        placement="right"
        @close="editclose"
        :visible="editvisible"
        :get-container="false"
        width="500"
      >
        <a-form-model
          :model="editform"
          ref="ruleForm_edit"
          :rules="editRules"
          style="position: relative; padding: 10px 40px"
        >
          <a-form-model-item prop="title" label="标题">
            <a-input placeholder="请输入" v-model="editform.title"> </a-input>
          </a-form-model-item>
          <a-form-model-item prop="content" label="发布内容">
            <a-textarea
              placeholder="请输入"
              v-model="editform.content"
              style="height: 400px"
            ></a-textarea>
          </a-form-model-item>
        </a-form-model>

        <div
          :style="{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e8e8e8',
            padding: '10px 16px',
            textAlign: 'right',
            left: 0,
            background: '#fff',
            borderRadius: '0 0 4px 4px',
          }"
        >
          <a-button @click="editclose"> 取消 </a-button> &ensp;
          <a-button
            @click="editsave"
            style="background-color: orange; color: white"
          >
            保存为草稿
          </a-button>
          &ensp;
          <a-button type="primary" @click="editsubmit"> 发布 </a-button>
        </div>
      </a-drawer>
    </div>
    <!-- 分页 -->
    <div
      style="
        display: block;
        width: 100%;
        height: 50px;
        text-align: center;
        margin-top: 45px;
      "
    >
      <a-pagination
        :current="curPage"
        :total="total"
        :pageSize="pageSize"
        show-quick-jumper
        @change="PageNOchange"
        style="display: inline-block"
      />
    </div>
  </div>
</template>

<script>
import {
  getAdvertiseNotice,
  insertAdvertiseNotice,
  updateAdvertiseNotice,
  delAdvertiseNotice,
} from "../../service/columnmanageapi";

export default {
  name: "NotificationAnnouncement",
  data() {
    return {
      title: "新增",
      advertise: [],
      visible: false,
      editvisible: false,
      editform: {},
      liveid: "",
      entity: {},
      total: 0,
      curPage: 1,
      pageSize: 6,
      addRules: {
        title: [
          { required: true, message: "请输入", trigger: "blur" },
          {
            validator: function (rule, value, callback) {
              if (/^[^\s]+[\s]*.*$/i.test(value) == false) {
                callback(new Error("请输入正确的名称"));
              } else {
                //校验通过
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        content: [
          { required: true, message: "请输入", trigger: "blur" },
          {
            validator: function (rule, value, callback) {
              if (value.trim().length === 0) {
                callback(new Error("请输入正确的内容"));
              } else if (value.trim().length > 500) {
                callback(new Error("输入内容不可超过500字"));
              } else {
                //校验通过
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
      editRules: {
        title: [
          { required: true, message: "请输入", trigger: "blur" },
          {
            validator: function (rule, value, callback) {
              if (/^[^\s]+[\s]*.*$/i.test(value) == false) {
                callback(new Error("请输入正确的名称"));
              } else {
                //校验通过
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        content: [
          { required: true, message: "请输入", trigger: "blur" },
          {
            validator: function (rule, value, callback) {
              // console.log(value,value.trim().length)
              if (value.trim().length === 0) {
                callback(new Error("请输入正确的内容"));
              } else if (value.trim().length > 500) {
                callback(new Error("输入内容不可超过500字"));
              } else {
                //校验通过
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.load();
  },
  computed: {
    count() {
      return this.$store.state.streamId;
    },
  },
  methods: {
    //  获取广告通知
    load() {
      getAdvertiseNotice({
        liveStreamId: this.count,
        curPage: this.curPage,
        pageSize: this.pageSize,
      }).then((res) => {
        if (res.code == 0) {
          this.advertise = res.data;
          this.total = res.count;
        }
      });
    },
    add() {
      this.visible = true;
      this.title = "新增";
    },
    // 取消关闭
    close() {
      this.visible = false;
      this.entity = {};
      this.$refs.ruleForm_add.resetFields();
    },
    // 保存为草稿
    save() {
      this.$refs.ruleForm_add.validate(async (valid) => {
        if (valid) {
          insertAdvertiseNotice({
            title: this.entity.title,
            content: this.entity.content,
            liveStreamId: this.count,
            isOpen: 0,
          }).then((res) => {
            if (res.code == 0) {
              this.$message.success("保存成功");
              this.visible = false;
              this.entity = {};
              this.load();
            } else {
              this.$message.success("保存失败");
              this.visible = false;
              this.load();
              this.entity = {};
            }
          });
        }
      });
    },
    // 发布
    submit() {
      this.$refs.ruleForm_add.validate(async (valid) => {
        if (valid) {
          insertAdvertiseNotice({
            title: this.entity.title,
            content: this.entity.content,
            liveStreamId: this.count,
            isOpen: 1,
          }).then((res) => {
            if (res.code == 0) {
              this.$message.success("发布成功");
              this.visible = false;
              this.entity = {};
              this.load();
            } else {
              this.$message.warning("发布失败");
              this.visible = false;
              this.entity = {};
              this.load();
            }
          });
        }
      });
    },
    // 编辑
    edit(item) {
      this.editform = item;
      this.editvisible = true;
      this.title = "编辑";
      this.load();
    },
    editclose() {
      this.editvisible = false;
      this.$refs.ruleForm_edit.resetFields();
      this.load();
    },
    //  编辑保存为草稿时
    editsave() {
      this.$refs.ruleForm_edit.validate(async (valid) => {
        if (valid) {
          updateAdvertiseNotice({
            //草稿编辑调用修改接口
            id: this.editform.id,
            title: this.editform.title,
            content: this.editform.content,
            liveStreamId: this.count,
            isOpen: 0,
          }).then((res) => {
            if (res.code == 0) {
              this.$message.success("编辑成功");
              this.load();
              this.editvisible = false;
              this.editform = {};
            } else {
              this.$message.warning("编辑失败");
              this.load();
              this.editvisible = false;
              this.editform = {};
            }
          });
        }
      });
    },
    // 编辑发布时
    editsubmit() {
      this.$refs.ruleForm_edit.validate(async (valid) => {
        if (valid) {
          updateAdvertiseNotice({
            id: this.editform.id,
            title: this.editform.title,
            content: this.editform.content,
            liveStreamId: this.count,
            isOpen: 1,
          }).then((res) => {
            if (res.code == 0) {
              this.$message.success("发布成功");
              this.load();
              this.editvisible = false;
            } else {
              this.$message.warning("发布失败");
              this.load();
              this.editvisible = false;
            }
          });
        }
      });
    },
    // 改变页码
    PageNOchange(curPage) {
      this.curPage = curPage;
      this.load();
    },
    //删除
    del(item) {
      delAdvertiseNotice(item.id).then((res) => {
        if (res.code == 0) {
          this.$message.success("删除成功");
          this.load();
        } else {
          this.$message.warning("删除失败");
          this.load();
        }
      });
    },
    submitAgain(item) {
      this.entity = item;
      this.visible = true;
      this.title = "重新发布";
      this.load();
    },
    cardSwitch(a, item) {
      this.editform = item;
      this.editform.isOpen = a;
      let data = {
        id: this.editform.id,
        isOpen: a,
      };
      // console.log(data);
      updateAdvertiseNotice(data).then((res) => {
        if (res.code == 0) {
          this.$message.success("修改成功");
          this.load();
        } else {
          this.$message.warning("修改失败,请重新发布");
          this.load();
        }
        this.load();
      });
    },
    cancel() {
      this.load();
    },
  },
};
</script>

<style scoped lang="scss">
.body {
  min-height: 500px;
}
.title {
  font-size: 15px;
  font-weight: 550;
  color: black;
  padding: 20px 10px;
  border-bottom: 1px solid #e9e9e9;
}

.addBtn {
  margin-left: 30px;
  margin-top: 20px;
}
.item {
  position: relative;
  width: 31%;
  height: 370px;
  // height: 100px;
  // aspect-ratio: 1/1.3;
  // float: left;
  display: inline-block;
  // background-color: #1890ff;
  border: 1.3px solid #e9e9e9;
  margin-left: 20px;
  margin-top: 15px;
  // margin-bottom: 20px;
  .top {
    width: 100%;
    height: 13%;
    border: 1px solid #e9e9e9;
    padding: 10px 10px;
    color: black;
    font-size: 18px;
  }
  .content {
    display: inline-block;
    margin-left: 5%;
    margin-top: 5%;
    width: 90%;
    height: 60%;
    border: 1.8px solid #e9e9e9;
    padding: 6px 6px 6px 6px;
    overflow: hidden;
    -webkit-line-clamp: 10;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  //   .ellipsis::after{
  //   	content: "...";
  //   	display: inline;
  // }
  .bottom {
    position: absolute;
    bottom: 0px;
    background-color: #f7f9fa;
    display: flex;
    justify-content: space-around;
    padding: 0 7px;
    width: 100%;
    height: 43px;
    line-height: 43px;
    cursor: pointer;
    span {
      display: inline-block;
      color: #e9e9e9;
      width: 2px;
    }
    a {
      color: #787878;
      display: inline-block;
      height: 100%;
      background-color: aqua;
    }
  }
}
</style>
